import { ArrowRightIcon } from "@heroicons/react/outline";
import PrimaryButton from "../../../components-reusable/Buttons/PrimaryButton/PrimaryButton";

const CallToAction = () => {
  return (
    <div className="bg-primary-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
          <span className="block">Ready to dive in?</span>
          <span className="block text-primary-600">
            Start your free trial today.
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <PrimaryButton
            href="/demo"
            size="xl"
          >
            Request a demo
            <ArrowRightIcon className="ml-2 w-5 h-5 animate-pulse" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
