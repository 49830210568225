import {
  CalendarIcon,
  ClockIcon,
  CogIcon,
  CubeIcon,
  CurrencyDollarIcon,
  HomeIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  MapIcon,
  OfficeBuildingIcon,
  RefreshIcon,
  ShoppingCartIcon,
  TruckIcon,
  UsersIcon,
  BookmarkAltIcon,
  SupportIcon,
  StarIcon,
} from "@heroicons/react/outline";
import Lang from "../components-reusable/Common/Lang/Lang";

const links = [
  {
    label: "Home",
    href: "/dashboard",
    icon: HomeIcon,
  },
  {
    label: <Lang plural>ORDER</Lang>,
    href: "/orders",
    icon: CubeIcon,
  },
  {
    label: "Calendar",
    href: "/calendar",
    icon: CalendarIcon,
  },
  // {
  //   label: "People",
  //   href: "/clients",
  //   icon: UsersIcon,
  //   childLinks: [
  //     {
  //       label: <Lang plural>CLIENT</Lang>,
  //       href: "/clients",
  //     },
  //     {
  //       label: <Lang plural>DRIVER</Lang>,
  //       href: "/providers",
  //     },
  //     {
  //       label: "Admins",
  //       href: "/providers",
  //     },
  //   ],
  // },
  {
    label: "Recurring",
    href: "/recurring",
    icon: RefreshIcon,
  },
  {
    label: "Dispatch",
    href: "/dispatch",
    icon: MapIcon,
  },
  {
    label: <Lang plural>CLIENT</Lang>,
    href: "/clients",
    icon: UsersIcon,
  },
  {
    label: "Accounts",
    href: "/accounts",
    icon: OfficeBuildingIcon,
  },
  {
    label: <Lang plural>DRIVER</Lang>,
    href: "/providers",
    icon: TruckIcon,
  },
  {
    label: "Locations",
    href: "/locations",
    icon: LocationMarkerIcon,
  },
  {
    label: "Schedule",
    href: "/schedules",
    icon: ClockIcon,
  },
  {
    label: "Services",
    href: "/services",
    icon: CurrencyDollarIcon,
  },
  {
    label: "Reviews",
    href: "/reviews",
    icon: StarIcon,
  },
  // {
  //   label: "POS",
  //   href: "/pos",
  //   icon: ShoppingCartIcon,
  // },
  // {
  //   label: "Forms",
  //   href: "/forms",
  //   icon: CubeIcon,
  // },
];

const publicLinks = [
  {
    label: "Features",
    href: "/",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
  // {
  //   label: "Industries",
  //   childLinks: [
  //     {
  //       name: "Residential Cleaning",
  //       href: "/industries/residential-cleaning",
  //     },
  //     {
  //       name: "Pressure Washing",
  //       href: "/industries/pressure-washing",
  //     },
  //     {
  //       name: "Carpet Cleaning",
  //       href: "/industries/pressure-washing",
  //     },
  //     {
  //       name: "Window Washing",
  //       href: "/industries/window-washing",
  //     },
  //   ],
  // },
  {
    label: "Resources",
    childLinks: [
      {
        name: "Service Area Map",
        description: "Create a custom map to show your custom service area.",
        href: "/service-area-map",
        icon: MapIcon,
      },
      // {
      //   name: "Help Center",
      //   description:
      //     "Get all of your questions answered in our forums or contact support.",
      //   href: "#",
      //   icon: SupportIcon,
      // },
      {
        name: "Guides",
        description: "Helpful tips and information to automate your business.",
        href: "/blog",
        icon: BookmarkAltIcon,
      },
    ],
  },
  // {
  //   label: "Contact",
  //   href: "/contact",
  // },
];

const bottomLinks = [
  {
    label: "Integrations",
    href: "/integrations",
    icon: LightningBoltIcon,
  },
  {
    label: "Settings",
    href: "/settings",
    icon: CogIcon,
  },
];

export { bottomLinks, publicLinks };

export default links;
