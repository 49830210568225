import Link from "next/link";

const BaseButton = ({
  size = "sm",
  children,
  onClick,
  href,
  variant,
  className,
  disabled,
  fullWidth,
  type = "button",
  target,
}) => {
  let sizeClassName;
  switch (size) {
    case "xs":
      sizeClassName = "px-2.5 py-1.5 px-2.5 py-1.5 text-xs font-medium";
      break;
    case "sm":
      sizeClassName = "px-3 py-2 text-sm leading-4 font-medium";
      break;
    case "md":
      sizeClassName = "px-4 py-2 text-sm font-medium ";
      break;
    case "lg":
      sizeClassName = "px-4 py-2 text-base font-medium ";
      break;
    case "xl":
      sizeClassName = "px-6 py-3 text-base font-medium ";
      break;

    default:
      break;
  }

  let variantClassName;
  switch (variant) {
    case "primary":
      variantClassName =
        "text-white dark:bg-primary-700 bg-primary-600 dark:bg-primary-700 disabled:hover:bg-primary-600 dark:disabled:hover:bg-primary-700 hover:bg-primary-700 shadow-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500";
      break;
    case "secondary":
      variantClassName =
        "text-gray-700 dark:text-gray-100 dark:hover:text-white bg-white dark:bg-gray-800 disabled:hover:bg-white dark:disabled:hover:bg-gray-800 hover:bg-gray-100 shadow-sm border border-gray-300 dark:border-gray-600 dark:hover:border-gray-500 dark:disabled:hover:border-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500";
      break;
    case "danger":
      variantClassName =
        "text-white bg-red-600 hover:bg-red-700 shadow-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500";
      break;
    case "transparent":
      variantClassName =
        "text-primary-700 dark:text-primary-300 hover:text-primary-500 dark:hover:text-primary-100 focus:outline-none focus:ring-0";
      break;

    default:
      break;
  }

  const finalButton = (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || false}
      className={`${sizeClassName} ${variantClassName} my-1 ${
        fullWidth ? "block w-full" : "inline-flex"
      } items-center rounded disabled:opacity-50 ${className}`}
    >
      {children}
    </button>
  );

  if (href && target) {
    return (
      <a href={href} target={target}>
        {finalButton}
      </a>
    );
  } else if (href) {
    return (
      <Link href={href} target={target}>
        {finalButton}
      </Link>
    );
  } else {
    return finalButton;
  }
};

export default BaseButton;
