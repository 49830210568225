import Logo from "../../Common/Logo/Logo";
import COMPANY from "../../../data/company";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:justify-between lg:px-8 items-end">
        <div className="mt-8 md:mt-0">
            <Logo
              width="200"
              themeOverride={"dark"}
              className="sm:m-0 m-auto opacity-95 hover:opacity-100 pb-8"
            />
          <div className="text-center sm:text-left text-base text-gray-100">
            <span>Made with </span>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="currentColor"
              className="w-5 h-5 inline mb-2 text-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            <span> in Columbus, Ohio</span>
          </div>
          <div className="text-center sm:text-left text-base text-gray-200">
            &copy; {currentYear} {COMPANY.NAME}, inc. All rights reserved.
          </div>
        </div>

        <div className="text-center mt-2 sm:mt-0 sm:text-right text-base">
          <div className="text-gray-100">Contact Us</div>
          <div className="text-gray-200">
            <a
              href={`mailto:${COMPANY.SUPPORT_EMAIL}`}
              className="hover:cursor-pointer hover:text-gray-200"
            >
              {COMPANY.SUPPORT_EMAIL}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
