/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import {
  MenuIcon,
  XIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { useRouter } from "next/router";
import Logo from "../../Common/Logo/Logo";
import { publicLinks } from "../../../data/navigation";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import Link from "next/link";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../../lib/cookie";
import FlyoutMenu from "./FlyoutMenu/FlyoutMenu";

export default function PublicTopNav() {
  const router = useRouter();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const currentUserTmp = getCurrentUser();
      setCurrentUser(currentUserTmp);
    }
  }, []);

  return (
    <>
      <div className="h-3 bg-primary-500" />
      <Disclosure as="nav" className="">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 my-5">
              <div className="relative flex justify-between">
                <div className="absolute inset-y-0 right-0 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <Link href="/">
                      <a>
                        <Logo width={220} />
                      </a>
                    </Link>
                  </div>
                  {/* Center links */}
                  <div className="flex-1 hidden md:ml-6 md:flex justify-center md:space-x-3">
                    {publicLinks.map((link) =>
                      link.href ? (
                        <a
                          href={link.href}
                          key={link.href}
                          className={`${
                            (router?.pathname ?? "") == link.href
                              ? "border-primary-500 text-primary-500 dark:text-gray-100 border-b-4"
                              : "border-transparent text-gray-600 dark:text-gray-300 hover:border-gray-700 hover:text-gray-700"
                          } inline-flex items-center px-4 py-4 border-b-2 text-md font-medium`}
                        >
                          {link.label}
                        </a>
                      ) : (
                        <div className="flex flex-col items-stretch justify-center ">
                          <FlyoutMenu key={"test"} links={link.childLinks}>
                            <div
                              className={`${
                                (router?.pathname ?? "") == link.href
                                  ? " text-primary-500 dark:text-gray-100"
                                  : " text-gray-600 dark:text-gray-300  hover:text-gray-700"
                              } inline-flex items-center px-4 py-4 text-md font-medium group`}
                            >
                              {link.label}

                              <ChevronDownIcon
                                className={"ml-2 h-5 w-5"}
                                aria-hidden="true"
                              />
                            </div>
                          </FlyoutMenu>
                        </div>
                      )
                    )}
                  </div>
                  {/* Right links (login/signup) */}
                  <div className="hidden md:flex flex-col justify-center">
                    {currentUser ? (
                      <PrimaryButton size="lg" href="/dashboard">
                        {currentUser.site_name}
                        <ArrowRightIcon className="ml-2 w-5 h-5" />
                      </PrimaryButton>
                    ) : (
                      <div className="flex">
                        <SecondaryButton size="lg" href="/login">
                          Log in
                        </SecondaryButton>
                        <PrimaryButton
                          size="lg"
                          href="/demo"
                          className="ml-3"
                        >
                          Request a Demo{" "}
                          <ArrowRightIcon className="ml-2 w-5 h-5" />
                        </PrimaryButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Mobile Menu */}
            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-4 space-y-1 bg-gray-100">
                {publicLinks.map((link) =>
                  link.href ? (
                    <Link href={link.href} key={link.href}>
                      <span
                        className={`${
                          (router?.pathname ?? "") == link.href
                            ? "bg-primary-50 border-primary-500 text-primary-700"
                            : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                        } block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer`}
                      >
                        {link.label}
                      </span>
                    </Link>
                  ) : (
                    <></>
                  )
                )}
                <div className="px-3 pt-1 pb-3">
                  {currentUser ? (
                    <PrimaryButton size="lg" fullWidth href="/dashboard">
                      <div className="flex items-center justify-center">
                        <span> {currentUser.site_name}</span>
                        <ArrowRightIcon className="ml-2 w-5 h-5" />
                      </div>
                    </PrimaryButton>
                  ) : (
                    <>
                      <SecondaryButton size="lg" fullWidth href="/login">
                        <div className="flex items-center justify-center">
                          <span>Log in</span>
                        </div>
                      </SecondaryButton>
                      <PrimaryButton
                        size="lg"
                        fullWidth
                        href="/demo"
                      >
                        <div className="flex items-center justify-center">
                          <span>Request a Demo</span>
                          <ArrowRightIcon className="ml-2 w-5 h-5" />
                        </div>
                      </PrimaryButton>
                    </>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
