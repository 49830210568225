/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];

export default function FlyoutMenu({ children, links }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button>{children}</Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden px-5 py-6 sm:p-8 bg-white">
                <div className="text-gray-400 text-sm uppercase mb-6">
                  Resources
                </div>
                <div className="relative grid grid-cols-1 gap-6 bg-white sm:gap-8 ">
                  {links.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-start rounded-lg bg-gray-50 hover:bg-gray-100 transition ease-in-out duration-150"
                    >
                      {item.icon && (
                        <item.icon
                          className="flex-shrink-0 h-6 w-6 text-primary-600 mt-1"
                          aria-hidden="true"
                        />
                      )}
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          {item.name}
                        </p>
                        {item.description && (
                          <p className="mt-1 text-sm text-gray-500">
                            {item.description}
                          </p>
                        )}
                      </div>
                    </a>
                  ))}
                </div>
                {/* <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                  <div>
                    <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                      Recent Posts
                    </h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {recentPosts.map((post) => (
                        <li key={post.id} className="text-base truncate">
                          <a
                            href={post.href}
                            className="font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                          >
                            {post.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-5 text-sm">
                    <a
                      href="/blog"
                      className="font-medium text-primary-600 hover:text-primary-500 transition ease-in-out duration-150"
                    >
                      {" "}
                      View all posts <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div> */}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
