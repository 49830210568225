import { useContext } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext/ThemeContext";

function Logo({ width, className, themeOverride }) {
  let { theme } = useContext(ThemeContext);
  if (themeOverride) theme = themeOverride;
  const logoUrl =
    theme === "dark" ? "/images/logo_light.png" : "/images/logo_dark.png";

  return (
    <img src={logoUrl} alt="Logo" width={width || 120} className={className} />
  );
}

export default Logo;
