import React from "react";
import { LanguageContext } from "../../../contexts/LanguageContext/LanguageContext";
import { getLanguageLabelFromKey } from "../../../helpers/languageHelper";

const Lang = ({ children, lowerCase = false, plural = false }) => {
  let { language } = React.useContext(LanguageContext);
  return <>{getLanguageLabelFromKey(language, children, lowerCase, plural)}</>;
};

export default Lang;
